<template>
  <div
    class="absolute top-[3.625rem] w-full overflow-y-auto overscroll-none bg-white px-6 pt-3 shadow-lg md:right-0 md:top-[7px] md:w-[700px] md:px-20 md:pb-0 md:pt-5 header:top-[3px]"
  >
    <FimButton
      type="secondary"
      class="absolute right-3 top-3 gap-0"
      @click="closeButtonClicked"
    >
      <template #icon>
        <IconFielmannClose class="size-5" />
      </template>
    </FimButton>
    <div v-if="fetching" class="flex flex-col gap-6 md:w-72 md:flex-row">
      <div class="space-y-1">
        <Skeleton v-for="n in 5" :key="n" class="w-[150px]" type="headline" />
      </div>
      <div class="space-y-1">
        <Skeleton v-for="n in 10" :key="n" class="w-[300px]" type="headline" />
      </div>
    </div>

    <template v-else>
      <div class="flex flex-col gap-6 md:flex-row md:gap-0">
        <div v-if="categories.length || brands.length" class="md:w-72">
          <SearchResultGroup
            v-if="categories.length"
            :items="categories.slice(0, 5)"
            :search-term="searchTerm"
            :label="$t('search.flyout.categories')"
            @click:result="
              (item: TypeaheadSuggestion) => $emit('click:result', item)
            "
          />
          <SearchResultGroup
            v-else-if="brands.length"
            :items="brands.slice(0, 5)"
            :search-term="searchTerm"
            :label="$t('search.flyout.categories')"
            @click:result="
              (item: TypeaheadSuggestion) => $emit('click:result', item)
            "
          />
        </div>
        <div v-if="productSuggestions.length" class="w-72 overflow-auto">
          <SearchResultGroup
            :items="productSuggestions.slice(0, PRODUCT_LIMIT)"
            :search-term="searchTerm"
            :label="$t('search.flyout.products')"
            :show-images="true"
            @click:result="
              (item: TypeaheadSuggestion) => $emit('click:result', item)
            "
          />
        </div>
      </div>

      <div
        v-if="resultsCount > 0"
        class="mt-4 overflow-auto border-t py-4 text-left"
      >
        <NuxtLink
          :to="localePath({ name: 'search', query: { term: searchTerm } })"
          data-testid="search-results-go-to-search-page-link"
          class="flex items-center"
          @click="closeButtonClicked"
          ><span v-dompurify-html="$t('search.search_results')" />
          <IconFielmannArrowRight class="ml-1 inline-block size-2.5" />
        </NuxtLink>
      </div>

      <section
        v-else-if="resultsCount <= 0 && searchTerm !== ''"
        class="px-8 py-4 text-center"
      >
        <p>{{ $t('search.no_result') }}</p>
      </section>
    </template>
  </div>
</template>

<script setup lang="ts">
import type { TypeaheadSuggestion } from '@scayle/storefront-nuxt'

const localePath = useFimLocalePath()

defineProps({
  fetching: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  brands: {
    type: Array as PropType<any[]>,
    default: () => [],
  },
  productSuggestions: {
    type: Array as PropType<any[]>,
    default: () => [],
  },
  categories: {
    type: Array as PropType<any[]>,
    default: () => [],
  },
  searchTerm: {
    type: String as PropType<string>,
    default: '',
  },
  resultsCount: {
    type: Number as PropType<number>,
    default: 0,
  },
})

const emit = defineEmits<{
  (e: 'onFlyoutDismiss'): void
  (e: 'click:result', item: TypeaheadSuggestion): void
}>()

const closeButtonClicked = () => emit('onFlyoutDismiss')
</script>
